img {
  pointer-events: none;
  width: 14px;
  height:14px;
}

body{
  background-image: url("./desktop.jpeg");
  font-family: sans-serif;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  width: 100vw;
  height:100vh;
}

.Application{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
}

.Application h5{
  font-style: normal;
  color: white;
}

.Application img{
  width: 50px;
  height: 50px;
}

.TaskBar{
  left: 0;
  top:3vh;
  text-align: left;
  width: 60px;
  height:97vh;
  background-color:rgba(16,4,14,0.3);
  position: absolute;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Bottom{
  margin-top: auto;
}


.TaskBar img{
  width:40px;
  height:40px;
}

.TopBar{
  left: 0;
  top:0;
  text-align: left;
  width: 100vw;
  height:3vh;
  background-color:#131313;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TopBar div{
  padding-left: 10px;
  padding-right: 10px;
}

.TopBarButtons{
  display:flex;
  justify-content: flex-end;
}

.TopBarButton{
  margin-right: 5px;
}

.App{
  top:0;
  border:0;
  margin:0;
  width:100%;
  height:100%;
}

#root {
  height: 100%;
  width: 100%;
}

.Activities{
  left: 0;
  text-align: left;
  border:0;
  padding: 0;
  margin-left: 2vw;
}

.Activities, h5{
  padding: 0;
  margin:0;
}

.ApplicationTopBar{
  width: 100%;
  height: 30px;
  min-height: 30px;
  background-color: #2C2C2C;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ApplicationTopBar button{
  width: 30px;
  height: 30px;
  background-color: rgba(0,0,0,0.0);
  border-width: 0;
}

.ApplicationTopBar button:active { 
  background-color: red; 
} 

.MinMaxClose{
  margin-right: 10px;
  right:0;
  position:absolute;
  margin-top: auto;
  margin-bottom: auto;
}

.applicationOptions{
  width: 150px;
  background-color: #3E3E3E;
  text-align: center;
  border-bottom-left-radius: 5px;
}


.RunningApplication{
  background-color:rgb(46, 13, 35);
  border-bottom-right-radius:5px;
  border-bottom-left-radius:5px;
  height: calc(100% - 30px);
  width: 100%;
  display: flex;
}

.ApplicationOptionsOption{
  border-color: #393939;
  border-width: 1px;
  border-style: solid;
  height:30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ApplicationOptionsOption:hover{
  background-color:#2C2C2C;
}

#active{
  border-color: #393939;
  background-color: #ffa200;
  border-width: 1px;
  border-style: solid;
  height:30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#active:hover{
  background-color: #d48703;
}

.Task{
  width: 60px;
  height: 60px;
  margin-top: 5px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Task:hover{
  background-color:rgb(184, 129, 175);
}

.Contents{
  text-align: center;
  width: 100%;
  height: 100%;
  overflow-y:scroll;
}



@media only screen and (max-width: 600px) {

}

.fiftyPix{
  margin-left:50px;
  margin-right: 50px;
  padding-top: 50px;
}

.DraggableApplication{
  border-color:#2C2C2C;
  border-width: 5px;
  border-radius:10px;
  border-style:solid;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  max-height: 100vh;
  min-width: 300px;
  min-height: 150px;
}


.ApplicationsPhone{
  padding-top: 30px;
  display:flex;
  flex-wrap: wrap;
  align-items: center;
}

.item{
  flex-basis: 25%;
}


.HomeButton{
  position: fixed;
  width: 100vw;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Circle{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #141414;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #808080;
  border-width: 1px;
  border-style: solid;
}


.ColouredDiv{
  border-radius: 15px;
  display: inline-block;
  margin:2px;
}

.ColouredDiv p{
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.PhoneApplication{
  background-color: #2C2D30;
  text-align: center;
  width: 100vw;
  min-height: 100vh;
}

.AboutMeDiv{
  background-color: #2C2D30;
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.EducationDiv{
  background-color: #2C2D30;
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.VolunteeringDiv{
  background-color: #2C2D30;
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.DescriptionDiv{
  background-color: #2197BD;
  width: 100%;
  min-height: 10vh;
  border-radius: 10px;
  text-align: center;
}

.Inline{

}


.MinMaxCloseButton{
  border-radius: 50%;
}

.Square{
  width:20px;
  height:20px;
  border-radius: 25%;
  border: black;
  border-width: 2px;
  border-style: solid;
}